.messaging__channel-header {
  background: #fff;
  transform: matrix(1, 0, 0, 1, 0, 0);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
  border-bottom: 1px solid #d8d9db; /* colors. gray.300 */
  line-height: 20px;
}

.messaging__channel-header .channel-header__name {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  font-size: 16px;
  color: #1c1d22; /* colors gray.800 */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#show-details {
  display: flex;
  align-items: center;
  white-space: nowrap;
  border: 1px solid #d8d9db; /* colors. gray.300 */
  padding: 8px 16px;
  position: absolute;
  right: 16px;
  border-radius: 20px;
  font-weight: normal;
  cursor: pointer;
}

.messaging__channel-header__right {
  display: flex;
  align-items: center;
  margin-right: 16px;
}

.messaging__channel-header__avatars {
  display: flex;
  align-items: center;
  height: 40px;
  min-width: 40px;
  max-width: 40px;
  border-radius: 20px;
  overflow: hidden;
  margin-right: 20px;
  margin-left: 20px;
}

.messaging__channel-header__avatars.two div:first-child {
  position: relative;
  right: 10px;
}

.messaging__channel-header__avatars.two div:nth-child(2) {
  position: relative;
  right: 14px;
}

.messaging__channel-header__avatars.two span {
  width: 20px;
  overflow: hidden;
}

.messaging__channel-header__avatars.three span {
  width: 20px;
  overflow: hidden;
}

.messaging__channel-header__avatars.four span:nth-child(2) {
  position: relative;
  right: 14px;
}

.channel-header__edit-input {
  width: 100%;
  font-family: 'Benton Sans', sans-serif;
  font-size: 17px;
  background: none;
  outline: none;
  border: none;
  padding: 0;
}

.channel-header__edit-input::placeholder {
  opacity: 50%;
}

#mobile-nav-icon {
  display: none;
}

@media screen and (max-width: 640px) {
  .messaging__channel-header {
    padding: 5px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    min-height: 40px;
    z-index: 9;
    display: flex;
    align-items: center;
  }

  .channel-header__name {
    line-height: 40px;
  }

  #mobile-nav-icon {
    display: inline-block;
    margin-top: 3px;
    margin-right: 10px;
  }

  #mobile-nav-icon svg path {
    fill: darkslategrey;
    stroke: darkslategrey;
    fill-opacity: 60%;
  }

  .messaging__channel-header__avatars {
    margin-left: 10px;
  }

  .messaging__channel-header {
    display: none;
  }
}
