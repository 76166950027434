/* ACTIONS */

.str-chat__message
  .str-chat__message-simple__actions__action--options
  .str-chat__message-actions-box--mine {
  background: #f1f1f1;
  box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.15), 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 0;
}

.str-chat__message-actions-list button {
  border-radius: 0 !important;
}

.str-chat__message-actions-list-item {
  font-weight: 500;
}

.str-chat__message-simple__actions {
  display: none;
  min-width: unset;
}

/* EDIT MESSAGE */

.str-chat__modal.str-chat__modal--open {
  border: none !important;
  opacity: 1;
  background: rgba(255, 255, 255, 0.5);
}

.str-chat__modal__inner {
  height: 160px;
  width: 400px;
  border: none !important;
  background: #f1f1f1;
  box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.15), 0px 2px 4px rgba(0, 0, 0, 0.15);
}

.str-chat__edit-message-form {
  position: relative;
  top: 40px;
}

.str-chat__modal .str-chat__edit-message-form textarea {
  border: 2px solid rgba(0, 0, 0, 0.16) !important;
  height: 50px !important;
  max-height: 50px !important;
  font-size: 15px;
  border-radius: 20px !important;
  transition: border 0.2s ease-in-out;
  box-shadow: none;
}

.str-chat__modal .str-chat__edit-message-form textarea:focus {
  border: 2px solid #006cff !important;
}

.str-chat__modal .str-chat__message-team-form-footer {
  margin-top: 12px;
}

.str-chat__modal .str-chat__message-team-form-footer button {
  cursor: pointer;
}

.str-chat__modal__close-button svg {
  top: 0px;
  margin-left: 8px;
}

.str-chat__modal__close-button {
  position: relative;
  top: -55px;
  left: 400px;
}

.str-chat.light .str-chat__modal__close-button svg {
  fill: #000000;
}

.str-chat.light .str-chat__modal__close-button {
  color: #000000;
}

.str-chat__modal__close-button:hover {
  color: #006cff !important;
  opacity: 1;
}

.str-chat__modal__close-button:hover svg {
  fill: #006cff !important;
}

.str-chat__modal .str-chat__message-team-form-footer svg:hover {
  fill: #006cff;
}

.str-chat__edit-message-form .rfu-file-upload-button {
  display: none;
}

.str-chat__edit-message-form .str-chat__message-team-form-footer button[type='submit'] {
  padding-right: 0;
}

.str-chat__edit-message-form .str-chat__message-team-form-footer button:focus {
  border: none;
  outline: none;
}

/* REACTIONS */

.str-chat__reaction-selector {
  /* border-radius: 16px; */
  height: 56px;
  background: #f1f1f1;
  box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.15), 0px 2px 4px rgba(0, 0, 0, 0.15);
}

.str-chat__reaction-selector li {
  font-size: 24px;
}

.str-chat__reaction-selector li span span {
  height: 24px !important;
  width: 24px !important;
}

.str-chat__reaction-list {
  display: none;
}

span.react-images__footer__count.react-images__footer__count--isModal {
  font-family: 'Benton Sans', sans-serif;
}

.str-chat__message.str-chat__message--me.str-chat__message-simple.str-chat__message-simple--me.str-chat__message--regular
  .str-chat__avatar {
  /* display: none; */
}

.str-chat__message.str-chat__message--me.str-chat__message-simple.str-chat__message-simple--me.str-chat__message--reply
  .str-chat__avatar {
  display: none;
}

.str-chat__message.str-chat__message--me.str-chat__message-simple.str-chat__message--deleted.deleted {
  margin: 0;
}

@media screen and (max-width: 640px) {
  /* REACTIONS */

  .str-chat__message-simple__actions__action--thread,
  .str-chat__message-simple__actions__action--reactions {
    display: flex;
  }

  .str-chat__message-simple__actions__action--options {
    display: flex;
  }

  .str-chat__message--me .str-chat__message-inner > .str-chat__message-simple__actions,
  .str-chat__message-simple--me .str-chat__message-inner > .str-chat__message-simple__actions {
    position: unset;
    margin-bottom: 6px;
  }

  .str-chat__message-inner > .str-chat__message-simple__actions,
  .str-chat__message-simple-inner > .str-chat__message-simple__actions {
    position: unset;
    margin-bottom: 6px;
  }

  /* EDIT MESSAGE */

  .str-chat__modal.str-chat__modal--open {
    padding-top: 200px;
  }

  .str-chat__modal__inner {
    max-width: 80vw;
  }

  .str-chat__modal .str-chat__edit-message-form {
    max-width: 80vw;
    min-width: unset;
  }

  .str-chat__modal__close-button {
    left: 75vw;
  }
}

/* basic message bubble styling */
.str-chat__li--single .str-chat__message--me .str-chat__message-text-inner {
  /* border-radius: 8px 8px 2px 8px !important; */
}
.str-chat__li--top .str-chat__message--me .str-chat__message-text-inner,
.str-chat__li--bottom .str-chat__message--me .str-chat__message-text-inner {
  /* border-radius: 8px 8px 2px 8px !important; */
}
.str-chat__message--me .str-chat__message-text-inner,
.str-chat__message-simple--me .str-chat__message-text-inner {
  /* border-radius: 8px 8px 2px 8px !important; */
}

/* receiving basic message style */
.str-chat__li--bottom .str-chat__message-text-inner,
.str-chat__li--middle .str-chat__message-text-inner,
.str-chat__li--top .str-chat__message-text-inner {
  /* border-radius: 8px 8px 8px 2px !important; */
}

.str-chat__li--top .str-chat__message-text-inner,
.str-chat__li--bottom .str-chat__message-text-inner,
.str-chat__li--middle .str-chat__message-text-inner,
.str-chat__li--single .str-chat__message-text-inner {
  font-size: 16px;
  padding: 14px 16px;
  /* border-radius: 8px 8px 8px 2px !important; */

  background: #fafafb; /* gray/bg-gray-100 */
  line-height: 26px;

  color: #292c33; /* font gray/bg-gray-700 */

  border-color: transparent;
}

/* .str-chat__message .str-chat__reaction-list {
  top: -24px;
} */
