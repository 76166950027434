.str-chat *,
.str-chat *::after,
.str-chat *::before {
  font-family: 'Benton Sans', sans-serif;
}

.str-chat.str-chat-channel.messaging {
  background: #fff;
}

.str-chat.str-chat-channel-list.messaging {
  background: #fff;
  border-right: 1px solid #d8d9db; /* colors. gray-300*/
}

.str-chat.str-chat-channel-list.messaging {
  background: #fff !important;
}

.str-chat__container {
  background: #fff !important;
}

.str-chat-channel.messaging .str-chat__main-panel {
  padding: 0 !important;
}

.messaging.str-chat .str-chat__list .str-chat__reverse-infinite-scroll {
  padding-top: 0;
}

.str-chat-channel.messaging .str-chat__main-panel {
  padding: 30px 30px 0 0px;
}

.str-chat-channel.messaging .str-chat__main-panel:not(:last-child) {
  padding: 20px 0 0 0px;
}

.str-chat-channel.messaging .str-chat__main-panel:not(:last-child) .messaging__channel-header {
  border-radius: 10px 0px 0px 0px;
}

.str-chat__message-simple-status {
  display: none;
}

.str-chat__message--system {
  padding: 20px;
}

.str-chat,
.str-chat-channel {
  max-height: 100%;
  height: 100%;
}

/* Mobile View */
@media screen and (max-width: 640px) {
  .str-chat-channel-list.messaging {
    float: unset;
  }

  #mobile-channel-list {
    width: 100vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: -100vw;
    transition: left 0.5s ease-in-out;
    z-index: 1000;
  }

  #mobile-channel-list.show {
    left: 0;
  }
}

/* To fix inherited styles (as per Team and Customer Support apps */
@media screen and (max-width: 960px) {
  .str-chat-channel-list.messaging {
    position: unset;
    left: unset;
    top: unset;
    z-index: unset;
    min-height: unset;
    box-shadow: unset;
    transition: unset;
  }
}

/* mobile / iphone */
@media screen and (max-width: 640px) {
  html,
  body {
    overflow: hidden;
    width: 100%;
  }

  html {
    touch-action: pan-y; /*prevent user scaling*/
  }

  body {
    position: relative;
  }

  #root {
    overflow: hidden;
  }

  .str-chat__list {
    overflow-x: hidden;
    overflow-y: scroll;
    height: 100%;
  }

  .str-chat__list ul {
    padding: 100px 0 50px 0;
  }
}

/* mobile / tablet */
@media screen and (hover: none) {
  html,
  body {
    overflow: hidden;
    width: 100%;
  }

  html {
    touch-action: pan-y; /*prevent user scaling*/
  }

  body {
    position: relative;
    min-height: 100vh;
    min-height: -webkit-fill-available; /* MUST ADD THIS AS IOS does not respect 100vh. It does not include the url bar and tabs when calculating */
  }

  #root {
    overflow: hidden;
  }

  .str-chat__list {
    overflow-x: hidden;
    overflow-y: scroll;
    height: 100%;
  }

  .str-chat,
  .str-chat-channel .str-chat__container .str-chat__main-panel {
    height: 100vh;
    height: -webkit-fill-available;
  }
}
