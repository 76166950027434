.inline-chat.str-chat {
  scrollbar-width: none;
}

.inline-chat.str-chat .str-chat__container {
  display: flex;
  flex-direction: column;
}

.inline-chat.str-chat-channel.messaging.light {
  box-shadow: none !important;
  width: 100%;
  height: auto;
}

.inline-chat .str-chat__list-notifications {
  display: none;
}

.inline-chat.str-chat.messaging .str-chat__list {
  padding: 0;
  height: 200px;
  max-height: 300px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
}

.inline-chat .str-chat__list::-webkit-scrollbar {
  background: transparent;
  display: 'none';
}

.inline-chat .str-chat__list::-webkit-scrollbar-track {
  background: transparent;
}

.inline-chat .str-chat__list::-webkit-scrollbar-thumb {
  background-color: transparent;
}
