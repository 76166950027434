.channel-preview__container {
  padding: 16px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  /* colors. gray/bg-gray-700 */
  color: #292c33;
}

.channel-preview__container:hover {
  background: #fafafb; /* colors. gray-100 */
  transition: background 0.1s ease-in-out;
}

.channel-preview__container.selected {
  background: #fafafb; /* colors. gray-100 */
  transition: background 0.1s ease-in-out;
}

.channel-preview__content-wrapper.unread {
  font-weight: bold;
}

.channel-preview__content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-left: 20px;
}
.channel-preview__content-top {
  margin-bottom: 8px;
}
.channel-preview__content-message-wrapper {
  margin: 0;
}

.channel-preview__content-message-wrapper,
.channel-preview__content-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.channel-preview__content-name {
  margin: 0;
  max-width: 158px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.channel-preview__content-time {
  /* fonts. xs/400 */
  font-size: 12px;
  /* gray/bg-gray-400 */
  color: #7d8088;
  margin: 0 0 0 10px;
  flex-shrink: 0;
}

.channel-preview__content-message {
  /* fonts. sm/400 */
  font-size: 14px;
  margin: 0;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.channel-preview__container .str-chat__avatar {
  margin-right: 0;
}

.channel-preview__avatars {
  display: flex;
  align-items: center;
  height: 40px;
  min-width: 40px;
  max-width: 40px;
  border-radius: 20px;
  overflow: hidden;
}

.channel-preview__avatars.two div:first-child {
  position: relative;
  right: 10px;
}

.channel-preview__avatars.two div:nth-child(2) {
  position: relative;
  right: 30px;
}

.channel-preview__avatars.two span {
  width: 20px;
  overflow: hidden;
}

.channel-preview__avatars.three span {
  width: 20px;
  overflow: hidden;
}
