/* This file only contains modifications to the default styles */

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker {
  border: none;
  border-radius: 10px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06); /* shadows.default */
  display: flex;
  flex-direction: column;
  font-family: 'Benton Sans', 'Helvetica Neue', 'Arial', sans-serif; /*  baseFont.body */
  font-size: 1rem; /* fontSize.base*/
  font-weight: 500;
}

.react-datepicker-popper[data-placement^='bottom'] {
  margin-top: 0px;
}

.react-datepicker__header {
  background-color: #fff;
  border-bottom: none;
  border-top-left-radius: 10px;
  padding-top: 1.5rem; /* space.6 */
}

.react-datepicker__current-month {
  font-family: 'Benton Sans', 'Helvetica Neue', 'Arial', sans-serif; /*  baseFont.body */
  font-size: 1.25rem; /* fontSize.xl*/
  color: #292c33 /* gray.700 */;
}

/* Necessary otherwise the top-right radius is not 10px */
.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: 10px;
}

.react-datepicker__navigation--previous {
  border-right-color: #292c33; /* gray.700 */
}

.react-datepicker__navigation--previous:hover {
  border-right-color: #292c33; /* gray.700 */
}

.react-datepicker__navigation--next {
  border-left-color: #292c33; /* gray.700 */
}

.react-datepicker__navigation--next:hover {
  border-left-color: #292c33; /* gray.700 */
}

/* Adjusts the width/height for the individual dates */
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 2.5rem;
  line-height: 2.5rem;
  margin: 0.2rem;
  color: #292c33 /* gray.700 */;
}

.react-datepicker__day-name {
  color: #7d8088; /* grey.400 */
}

.react-datepicker__day--outside-month {
  color: #d8d9db; /* grey.300 */
}

.react-datepicker__day:hover {
  border-radius: 9999px;
  background-color: #f0f0f0;
}

.react-datepicker__day--selected {
  border-radius: 9999px;
  background-color: #20954e; /* green.700 */
  color: #fff;
}

.react-datepicker__day--selected:hover {
  border-radius: 9999px;
  background-color: #20954e;
  color: #fff;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 9999px;
  background-color: #f0f0f0;
  color: #292c33 /* gray.700 */;
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  border-radius: 9999px;
  background-color: #f0f0f0; /* grey.400 */
  color: #292c33 /* gray.700 */;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  color: #d8d9db; /* grey.300 */
}

.react-datepicker__navigation {
  margin-top: 1.25rem; /* space.5 */
}

.react-datepicker__month-container {
  float: none;
}

.react-datepicker__close-icon {
  padding: 0.25rem;
  font-weight: 600; /* fontWeights.semiBold */
  line-height: 1.5rem;
  right: 0.5rem;
}

.react-datepicker__close-icon::before,
.react-datepicker__close-icon::after {
  background-color: transparent;
  font-size: 1.5rem;
  color: inherit;
  width: 24px;
  height: 24px;
}

.react-datepicker__navigation--next {
  background: url('data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB2aWV3Qm94PSIwIDAgMjQgMjQiIHN0cm9rZT0iY3VycmVudENvbG9yIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogIDxwYXRoIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyIiBkPSJNOSA1bDcgNy03IDciLz4KPC9zdmc+Cg==')
    no-repeat; /* This icon is chevron-right*/
  width: 24px;
  height: 24px;
  border: none;
}

/* For isMobile/portal */
.react-datepicker__portal .react-datepicker__navigation--next {
  background: url('data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB2aWV3Qm94PSIwIDAgMjQgMjQiIHN0cm9rZT0iY3VycmVudENvbG9yIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogIDxwYXRoIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyIiBkPSJNOSA1bDcgNy03IDciLz4KPC9zdmc+Cg==')
    no-repeat; /* This icon is chevron-right*/
  width: 24px;
  height: 24px;
  border: none;
}

.react-datepicker__navigation--previous {
  background: url('data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB2aWV3Qm94PSIwIDAgMjQgMjQiIHN0cm9rZT0iY3VycmVudENvbG9yIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogIDxwYXRoIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyIiBkPSJNMTUgMTlsLTctNyA3LTciLz4KPC9zdmc+Cg==')
    no-repeat; /* This icon is chevron-left */
  width: 24px;
  height: 24px;
  border: none;
}

/* For isMobile/portal */
.react-datepicker__portal .react-datepicker__navigation--previous {
  background: url('data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB2aWV3Qm94PSIwIDAgMjQgMjQiIHN0cm9rZT0iY3VycmVudENvbG9yIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogIDxwYXRoIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyIiBkPSJNMTUgMTlsLTctNyA3LTciLz4KPC9zdmc+Cg==')
    no-repeat; /* This icon is chevron-left */
  width: 24px;
  height: 24px;
  border: none;
}
