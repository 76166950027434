.str-chat__avatar-fallback {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: normal;
  /* colors. gray/bg-gray-200 */
  background-color: #ecedee;

  /* gray/bg-gray-600 */
  color: #373a44;

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
}
