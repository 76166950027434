.messaging__channel-list {
  width: 330px;
  height: 100%;
  overflow: hidden;
}

.messaging__channel-list__channels {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.messaging__channel-list__header {
  display: flex;
  align-items: center;
  padding: 16px 20px;
  border-bottom: 1px solid #d8d9db; /* colors. */
}

.messaging__channel-list__message {
  margin-left: 30px;
  margin-top: 30px;
  color: #000;
}

.messaging__channel-list__header__name {
  display: flex;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #000;
}

.str-chat__load-more-button {
  display: none;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -400px 0;
  }
  100% {
    background-position: 400px 0;
  }
}

.str-chat__date-separator {
  justify-content: center;
}

.str-chat__date-separator-date {
  /* fonts. xs/600 */
  font-size: 12px;
  font-weight: bold;
  /* colors. gray/bg-gray-400 */
  color: #7d8088;
}

.str-chat__date-separator-line {
  display: none;
}

@media screen and (max-width: 640px) {
  .messaging__channel-list {
    width: unset;
  }
  .messaging__channel-list__channels {
    height: 100%;
    height: -webkit-fill-available;
  }

  .messaging__channel-list__header {
    display: none;
  }
}
